import EventstreamClient from 'eventstream.js/dist/eventstream';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'utils/environment';
import { getOrSetSavt } from 'utils/cookies';

export class EventTracker {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private client: any; // TODO: remove any
  public buyerToken?: string;
  public anonymousId?: string;
  public sessionId?: string;

  constructor() {
    this.client = new EventstreamClient({
      applicationName: 'buyerportal',
      environment: environment().name,
    });

    // assign uuid to unloggedin user
    this.anonymousId = uuidv4();

    // setup session id
    const savt = getOrSetSavt();

    this.sessionId = savt;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  track(data?: any) {
    this.client.trackV2WithDefaults({
      anonymousId: this.anonymousId ?? undefined,
      catalog_name: 'buyerportal_events',
      device_screen_height: undefined,
      device_screen_width: undefined,
      sessionId: this.sessionId,
      track_cookie_campaign_channel: undefined,
      track_cookie_detail_channel: undefined,
      track_cookie_device_channel: undefined,
      track_cookie_forecast_channel: undefined,
      track_cookie_location_channel: undefined,
      track_cookie_offer_channel: undefined,
      track_cookie_partner_channel: undefined,
      track_cookie_product_channel: undefined,
      userId: this.buyerToken ?? undefined,
      ...data,
    });
  }

  setBuyerToken(buyerToken: string) {
    this.buyerToken = buyerToken;
    this.anonymousId = undefined;
  }
}

const tracker = new EventTracker();

export default tracker;
