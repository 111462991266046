import { DataType } from '../../../rpc/model/squareup/feature/relay/common/data_type';
import { GetFlagResponse } from 'rpc/model/squareup/feature/relay/flags/message/get_flags';

/*
 * These flags will automatically be loaded into the store after fetching the buyer.
 */
export enum BoolFlag {
  useAppSubdomain = 'domainseparation-use-app-subdomain',
  useNewGiftCardFlow = 'giftcard-use-new-square-profile-flow',
  showCookieConsent = 'sq-profile-show-cookie-consent',
  showReceipts = 'buyerportal-should-show-square-profile-receipts',
}

/*
 * These flags must be loaded into the store manually.
 */
export enum AnonymousBoolFlag {
  enableMerchantPortal = 'buyerportal-is-merchant-portal-available',
  manuallyTrackPageViews = 'crm-track-square-profile-views-manually',
  addReportReason = 'cus-buyerportal-fe-add-report-reason',
}

const isValidBoolFlag = (flagName: string): flagName is BoolFlag => {
  const isBoolFlag = (
    Object.keys(BoolFlag) as Array<keyof typeof BoolFlag>
  ).some((k) => BoolFlag[k] === flagName);

  const isAnonymousBoolFlag = (
    Object.keys(AnonymousBoolFlag) as Array<keyof typeof AnonymousBoolFlag>
  ).some((k) => AnonymousBoolFlag[k] === flagName);

  return isBoolFlag || isAnonymousBoolFlag;
};

export type BoolFlagConfig = {
  [key in BoolFlag | AnonymousBoolFlag]: boolean;
};

export type Flags = {
  boolean: BoolFlagConfig;
};

export type PartialFlags = {
  boolean: Partial<BoolFlagConfig>;
};

export const CreateDefaultBoolFlagConfig = (): BoolFlagConfig => {
  return [
    ...Object.values(BoolFlag),
    ...Object.values(AnonymousBoolFlag),
  ].reduce<BoolFlagConfig>((accum, curr) => {
    return {
      ...accum,
      [curr]: false,
    };
  }, {} as BoolFlagConfig);
};

export const FlagConverter = {
  fromRpcGetFlagsResponse(response: readonly GetFlagResponse[]): PartialFlags {
    const flags: PartialFlags = {
      boolean: {},
    };

    response.forEach((flag) => {
      switch (flag.flagType) {
        case DataType.BOOLEAN: {
          if (isValidBoolFlag(flag.flagName)) {
            flags.boolean[flag.flagName] = flag.boolValue;
          }
          break;
        }
        default: {
          break;
        }
      }
    });

    return flags;
  },
};
